export const initHero = () => {

  const mockPriceInvoice = document.querySelector('#RS-intro-product-mock-price-invoice');
  const mockPriceMarket = document.querySelector('#RS-intro-product-mock-price-market');
  const mockPriceSavings = document.querySelector('#RS-intro-product-mock-price-savings');
  const overlayElement = document.querySelector('.RS-intro-product-mock-bottom__overlay');
  const mockCursors = document.querySelectorAll('.RS-intro-product-mock-cursor');
  const overlayLabel = overlayElement.querySelector('.RS-intro-product-mock-bottom__overlay-label');
  const overlayElementVisibilityDuration = 2500;
  const initialDelay = 3000;
  const intervalDelay = 6000;
  const showCursorOffset = 800;
  const showCursorDuration = 2000;

  let previousPriceOver10000 = false;
  let currentCursorIndex = 0;

  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const generateRandomValues = () => {
    let currentPrice;
    if (previousPriceOver10000) {
      currentPrice = Math.floor(Math.random() * (9999 - 2000 + 1)) + 2000;
    } else {
      currentPrice = Math.floor(Math.random() * (20000 - 10000 + 1)) + 10000;
    }
    previousPriceOver10000 = currentPrice > 10000;

    const savingsPercentage = Math.random() * (20 - 8) + 8;
    const marketPrice = currentPrice - (currentPrice * (savingsPercentage / 100));
    const savings = ((currentPrice - marketPrice) / currentPrice) * 100;

    setTimeout(() => {
      setTimeout(() => {
        mockPriceInvoice.textContent = formatPrice(currentPrice.toFixed(0));
        mockPriceMarket.textContent = formatPrice(marketPrice.toFixed(0));
        mockPriceSavings.textContent = `${savings.toFixed(1)}`;
        mockPriceInvoice.classList.add('RS-intro-product-mock-price--hidden');
        mockPriceMarket.classList.add('RS-intro-product-mock-price--hidden');
        overlayLabel.textContent = "terminu płatności";
      }, overlayElementVisibilityDuration / 2);

      overlayElement.classList.add('RS-intro-product-mock-bottom__overlay--visible');
      overlayLabel.textContent = "ceny bazowej";

      setTimeout(() => {
        overlayElement.classList.remove('RS-intro-product-mock-bottom__overlay--visible');
        mockPriceInvoice.classList.remove('RS-intro-product-mock-price--hidden');
        mockPriceMarket.classList.remove('RS-intro-product-mock-price--hidden');
      }, overlayElementVisibilityDuration);

    }, initialDelay);

    setTimeout(() => {
      mockCursors.forEach((cursor, index) => {
        if (index === currentCursorIndex) {
          cursor.classList.add('RS-intro-product-mock-cursor--visible');
        } else {
          cursor.classList.remove('RS-intro-product-mock-cursor--visible');
        }
      });

      setTimeout(() => {
        mockCursors[currentCursorIndex].classList.remove('RS-intro-product-mock-cursor--visible');
        currentCursorIndex = (currentCursorIndex + 1) % mockCursors.length;
      }, showCursorDuration);
    }, initialDelay - showCursorOffset);
  };

  generateRandomValues();
  setInterval(generateRandomValues, intervalDelay);

};